import React, {createRef, useState, useEffect} from "react";
import { Space, Tag, Button, Input, Image,Swiper } from "antd-mobile";
import styled from "styled-components";
import { useDrag } from "@use-gesture/react";
import {
    useFBX,
} from "@react-three/drei";
import Audio from "./Audio";
import {getUrlParam} from "../Model";
import FlowingLineBack from "./FlowingLineBack";

export default () => {
    const c = createRef();
    const bind = useDrag(state => {
        let {
            direction: [x, y],
        } = state;
        handleDrag(y);
    });
    const [curStatus, setCurStatus] = useState("expand");
    const [pageData, setPageData] = useState("");
    const [imageViewerVisible, setImageViewerVisible] = useState(false)
    const [source, setSource] = useState("3d")
    const [backgroundImage,setBackgroundImage] = useState("https://dadiwan.oss-cn-hangzhou.aliyuncs.com/assets/images/viewantique-04-img_bj400.png.jpg");

    const handleDrag = y => {
        if (y == -1) {
            //上滑
            if (curStatus == "retract") return;
            if (!backgroundImage){
                setSource('image')
            }
            setCurStatus(() => "retract");

        } else if (y == 1) {
            //下滑
            if (curStatus == "expand") return;
            setSource("3d")
            setCurStatus(() => "expand");
        }
    };
	const playAudio= ()=>{
		console.log(pageData.audioIntroduction)
	}
    useEffect(()=>{
        fetch(`https://panos.rwone.xyz/app-api/ddw/relic/get?id=${getUrlParam('id')??10}`, {
            method: 'GET',
            headers: {
              'tenant-id': 151
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.code === 0) {
                document.title = data.data.name;
                // let { address } = data.data
                // address = 'https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/Painting/RMP%281%29.FBX'
                setPageData(data.data)
            }
        })
        .catch(error => {
            // 处理错误
            console.log(error);
        });
    },[])
    return (
        <StyleWrapper bg={backgroundImage}>
			<div className="detail_voice_audio">
				<Audio src={pageData.audioIntroduction} cnSrc={pageData.audioIntroduction} enSrc={pageData.audioIntroductionEn} />
			</div>
            <div className="titlename">{pageData.name}</div>
            <div className={`preview preview_${curStatus}`}>

                { pageData.address && pageData.address != '' && source === '3d' &&  <FlowingLineBack
                    curStatus={curStatus}
                    modelSrc={pageData.address}
                /> }

                { !backgroundImage && pageData.imageGallery && source === '3d' && <div class='swiper-wrapper'>
                    <Image src={pageData.imageGallery.split(',')[0]} fit='cover' />

				</div>}
                { pageData.imageGallery && source === 'image' && <div class='swiper-wrapper'>
                    <Swiper indicatorProps={{
                        color: 'white',
                    }}>
                        {
                            pageData.imageGallery?.split(',').map((img, index) => (
                                <Swiper.Item>
                                    <Image src={img} fit='cover' />
                                </Swiper.Item>
                            ))
                        }
                    </Swiper>
                </div> }
				<Image className="bottom" src='https://dadiwan.oss-cn-hangzhou.aliyuncs.com/assets/images/viewantique-04-img_wenwu_bj.png' fit='cover' />
				<div className="hua-out">
					<Image className="hua-icon " src='https://dadiwan.oss-cn-hangzhou.aliyuncs.com/assets/images/viewantique-04-img_huadongtishi.png' />
					<div className="hua-text">滑动查看细节</div>
				</div>

                {/* <div className="preview_text" {...bind()}>
                    {
                        backgroundImage && <img className="preview_text_tool_img" src='https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E7%BB%84%20609.png'/>
                    }
                    <span className="preview_text_name">{ pageData.name }</span>
                    <span className={`preview_text_icon ${curStatus == 'expand'?'animate__animated animate__fadeInUp animate__infinite':''}`}></span>
                    <span className="preview_text_tips">上滑查看更多</span>
                </div> */}
            </div>
            <div className="detail-wrapper">
                <div className="detail">
                    <div className="detail_title">{ pageData.name }</div>
                    <div className="detail_label">
                        <Space>
                        {
                            pageData.tags && pageData.tags.split(',').map(item => {
                                return (<Tag key={item} color="#DF891F" fill="outline">
                                    {item}
                                </Tag>)
                            })
                        }
                        </Space>
                    </div>
                    {/*<div className="detail_voice">*/}
                    {/* <img className="detail_voice_taotao" src={require('./img/detail_voice_taotao.png')}></img>*/}
                    {/* <div className="detail_voice_audio">*/}
                    {/*     <Audio src={pageData.audioIntroduction} cnSrc={pageData.audioIntroduction} enSrc={pageData.audioIntroductionEn} />*/}
                    {/* </div>*/}
                    {/*</div>*/}
                    <div className="detail_body">
                        <div className="detail_body_title">简介</div>
                        <div className="detail_body_content" dangerouslySetInnerHTML={{ __html: pageData.introduction }}>
                        </div>
                    </div>
                    {/*<div className="detail_comment">*/}
                    {/* <Input*/}
                    {/*     className="detail_comment_input"*/}
                    {/*     placeholder="说点什么..."*/}
                    {/*     // value={value}*/}
                    {/*     // onChange={val => {*/}
                    {/*     //  setValue(val);*/}
                    {/*     // }}*/}
                    {/* />*/}
                    {/* <div className="detail_comment_actions">*/}
                    {/*     <Button>*/}
                    {/*         <Space>*/}
                    {/*             <span className="detail_comment_actions_forward"></span>*/}
                    {/*             <span>分享</span>*/}
                    {/*         </Space>*/}
                    {/*     </Button>*/}
                    {/*     <Button>*/}
                    {/*         <Space>*/}
                    {/*         <span className="detail_comment_actions_xing"></span>*/}
                    {/*             <span>收藏</span>*/}
                    {/*         </Space>*/}
                    {/*     </Button>*/}
                    {/* </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </StyleWrapper>
    );
};
const StyleWrapper = styled.div`
    @font-face {
        font-family: "SourceHanSerifCN";
        src: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/%E5%AD%97%E4%BD%93/SourceHanSerifCN-Regularmini.otf");
    };
    @font-face {
        font-family: "SourceHanSerifCNBold";
        src: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/%E5%AD%97%E4%BD%93/SourceHanSerifCN-Boldmini.otf");
    };

    & * {
        transition: all 1s ease-out;
    }

    height: 100vh;
    display: flex;
    background-repeat: no-repeat;
    flex-direction: column;
    background-color: #1a1a1a;
    background-image: url(${props => props.bg || 'https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E8%83%8C%E6%99%AF.png'});
    background-size: 100% 100%;
    .preview_text_tool_img {
        height: 60px;
        transform: translateY(-10px);
    }

    .button-tool {
        position: absolute;
        z-index: 333;
    }

    .preview_retract {
        height: 40%;
        flex-shrink: 1;

        .preview_light {
            opacity: 0;
        }

        .preview_img {
            height: 70%;
        }

        .preview_text {
            .preview_text_name,
            .preview_text_icon,
            .preview_text_tips {
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            .preview_text_look {
                opacity: 1;
                transition: all 2.5s ease-out;
            }
        }

        .preview_text_tool_img {
            opacity: 0;
        }
    }

    .preview_expand {
        height: 100%;
        flex-shrink: 0;

        .preview_light {
            opacity: 1;
        }

        .preview_img {
            height: 33%;
        }

        .preview_text {
            .preview_text_name,
            .preview_text_icon,
            .preview_text_tips {
                opacity: 1;
                transition: all 2.5s ease-out;
            }

            .preview_text_look {
                opacity: 0;
                transition: all 0.3s ease-out;
            }
        }

        .preview_stage {
            opacity: 1;
        }
    }

    .preview {
        background-size: 100% 100%;
        position: relative;

        .preview_stage {
            position: absolute;
            bottom: 12%;
            width: 100%;
            height: 80px;
            left: 0;
            right: 0;
            transform: translate(1%, -25%);
            background: url("https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E7%BB%84%20453.png") no-repeat;
            background-size: 100% 100%;
        }

        .preview_img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
                height: 100%;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 80%;
                width: 100%;
                height: 100%;
                z-index: -1;
                background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_base.png") no-repeat;
                background-size: 100%;
            }
        }

        .preview_text {
            touch-action: none;
            position: absolute;
            z-index: 99999;
            bottom: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: SourceHanSerifCN;
            color: #ffffff;

            .preview_text_name {
                font-size: 19px;
                font-family: SourceHanSerifCNBold;
            }

            .preview_text_icon {
                height: 20px;
                width: 20px;
                margin: 10px;
                background: url("https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E7%BB%84%20608.png") no-repeat;
                background-size: 100%;
            }

            .preview_text_tips {
                font-size: 12px;
                font-weight: bold;
                color: #bcbcbc;
            }

            .preview_text_look {
                font-size: 14px;
                align-self: flex-end;
                margin-right: 20px;
            }
        }
    }

    .detail-wrapper {
        flex: 1;
        z-index: 999;
        overflow: hidden;
        //background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_bg.png")
        //  no-repeat;
        background-size: 100% 100%;
        //背景与第一个盒子相交处有白边
        padding-top: 3px;
        margin-top: -2px;
    }

    .detail {
        position: relative;
        height: 100%;
        padding-top: 23.5px;
        display: flex;
        flex-direction: column;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_contentBg.png") no-repeat;
        background-size: 100% 100%;
        background-color: #fff;
        font-family: "SourceHanSerifCN";

        & > * {
        }

        .detail_title {
            height: 30px;
            line-height: 30px;
            font-size: 22px;
            padding: 0 20px;
            font-family: SourceHanSerifCNBold;
        }

        .detail_label {
            padding: 6.5px 20px 15px 20px;

            .adm-tag {
                padding: 4px 8px;
            }
        }

        .detail_voice {
            position: relative;
            width: 100%;
            height: 99px;
            margin: 0px 14.5px 0px 13.5px;

            .detail_voice_taotao {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 99px;
                width: 52.5px;
            }

            .detail_voice_audio {
                margin-left: 20px;
                margin-top: 40px;
                width: 326.5px;
                height: 52px;
                border-radius: 26px;
                background: #FFF;
                box-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.2);
            }
        }

        .detail_body {
            padding: 0 21px 0 19px;
            margin-bottom: 10px;
            font-size: 14px;
            color: #4c4c4c;
            flex: 1;
            overflow: scroll;

            .detail_body_title {
                height: 19px;
                line-height: 19px;
                margin-top: 16.5px;
                font-size: 22px;
                color: #000000;
            }

            .detail_body_content {
                width: 100%;
                height: 245.5px;
                margin-top: 13px;
                text-indent: 2em;
                text-align: justify;
                word-break: break-all;
                white-space: pre-line;
                font-size: 14px;
                font-weight: normal;
                line-height: 27.5px;
                p{
                    padding-bottom: 40px;
                }
            }
        }

        .detail_comment {
            margin: 0 20px;
            height: 70px;
            display: flex;
            align-items: baseline;
            position: relative;

            .detail_comment_input {
                width: 50%;
                height: 50%;
                background: #f0ddbb;
                border-radius: 40px;
                --placeholder-color: #7f7f7f;
                --font-size: 14px;

                input {
                    text-indent: 1em;
                }
            }

            .detail_comment_actions {
                height: 50%;
                position: absolute;
                right: 0;
                display: flex;
                align-items: center;
                font-size: 14px;

                .adm-button {
                    padding: 0;
                    margin-left: 8px;
                    background: transparent;
                    font-size: 14px;
                    color: #7F7F7F;
                    border: none;

                    &::after {
                        display: none
                    }

                    .adm-space {
                        --gap: 5px
                    }

                    .adm-space, .adm-space > .adm-space-item {
                        display: flex;
                        align-items: center;
                    }
                }

                .detail_comment_actions_forward, .detail_comment_actions_xing {
                    display: block;
                    width: 17px;
                    height: 17px;

                }

                .detail_comment_actions_forward {
                    background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_forward.png") no-repeat;
                    background-size: 100% 100%;
                }

                .detail_comment_actions_xing {
                    background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_xing.png") no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    .detail-logo {
        margin: 10px;
        width: 300px;
    }

    .detail-title {
        font-family: SourceHanSerifCN;
        font-size: 16px;
        color: #FFF2D5;
    }

    .preview-switch-checkbox{
        font-family: SourceHanSerifCNBold;
        background-color: rgba(0, 0, 0, 0.47) !important;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .preview-switch-checked {
        width: 120px;
    }

    .preview-switch-checked-item {
        width: 58px;
        flex-shrink: 0;
        text-align: center;
        height: 36px;
        line-height: 36px;
        border-radius: 31px;
        transition:initial;
    }

    .preview-switch-handle {
        color: #000000;
        background-color: #ffffff;
    }

    .swiper-wrapper{
        height: 100%;
        display: flex;
        align-items: center;
    }
    .ip-post{
        margin-top: 50px;
        position: absolute;
        left: 6%;
		z-index: 999999;
        .ren-ip{
          position: absolute;
          width: 44px;
          height: auto;
        }
        .ren-s{
          width:150px;
          height: auto;
          position: absolute;
          left: 70px;
          top: 2vh;
        }
      }
      .titlename{
        font-size: 30px;
        position: absolute;
        top: 19vh;
        text-align: center;
    	width: 100%;
        color: #fff;
        font-family: AlimamaShuHeiTi;
        font-weight: bold;
        font-size: 51rpx;
        color: #000000;
        line-height: 83rpx;
        background: linear-gradient(0deg, #FFFFFF 0%, #FFD8AE 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
	  .hua-icon{
		position: absolute;
		bottom: 13vh;
		height: auto;
		display: flex;
		justify-content: center;
		animation: 2s rowLeft linear infinite;
		animation-direction: alternate;
		img{
			width:80px;
		}
	  }
	  .hua-out{
		display: flex;
		width: 100%;
		justify-content: center;
	  }
		@keyframes rowLeft {
			0% {
			transform: translate3d(-30px, 0, 0);
			}

			100% {
			transform: translate3d(30px, 0, 0);
			}
		}
	  .hua-text{
		width: 100%;
		position: absolute;
		bottom: 9vh;
		color: #fff;
		text-align: center;
	  }
	  .bottom{
		position: absolute;
		bottom: 33vh;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		img{
			width: 55%;
		}
	  }
`;
