export default {
  Q0:{
    title:"",
    desc:"永恒的凝望",
    noShowQuestion:true,
    detailPosition:[0, 1, 2.8],
    question:[
      {
        key1:"",
        key2:"",

      }
    ]
  },
  Q1:{
    title:"此时此刻的状态是？",
    desc:"我们无法得知",
    detailPosition:[0, -1, 7],
    question:[
      {
        key1:"她在",
        key2:"微笑",
      },
      {
        key1:"她在",
        key2:"思考",
      },
      {
        key1:"她在",
        key2:"凝视",
      }
    ]
  },
  Q2:{
    title:"她为何存在？",
    desc:"很难有准确答案",
    detailPosition:[0, 0, 5.4],
    question:[
      {
        key1:"作为",
        key2:"装饰",
      },
      {
        key1:"作为",
        key2:"容器",
      },
      {
        key1:"作为",
        key2:"祭祀",
      }
    ]
  },
  Q3:{
    title:"她为何存在？",
    desc:"很难有准确答案",
    detailPosition:[0, 0, 6],
    question:[
      {
        key1:"作为",
        key2:"装饰",
      },
      {
        key1:"作为",
        key2:"容器",
      },
      {
        key1:"作为",
        key2:"祭祀",
      }
    ]
  }
}
