import * as THREE from "three";
import React, { useState } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import {
	AccumulativeShadows,
	RandomizedLight,
	Html,
	Environment,
	Center,
	PresentationControls,
	useFBX,
	Preload
} from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { RGBELoader } from "three-stdlib";

function Ring({ map, ...props }) {
	const fbx = useFBX(
		"https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/detail/fbx/dad.FBX"
	);
	return (
		<group {...props} dispose={null}>
			<primitive object={fbx} scale={3} position={[0, 0, 15]} />
		</group>
	);
}

export default function App() {
	const texture = useLoader(
		RGBELoader,
		"https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/peppermint_powerplant_2_1k.hdr"
	);
	texture.mapping = THREE.EquirectangularReflectionMapping;
	return (
		<Canvas
			shadows
			camera={{ position: [0, 0, 15], fov: 35, near: 1, far: 30 }}
		>
			<color attach="background" args={["#f0f0f0"]} />
			<ambientLight />
			<Environment map={texture} />
			<mesh>
				<Html rotation={[Math.PI, 0, Math.PI]}>
					<div style={{ color: "#000", transform: "" }}>
						模型 解释
					</div>
				</Html>
			</mesh>
			<PresentationControls
				global
				speed={9}
				config={{ mass: 1, tension: 250, friction: 25 }}
				zoom={1.25}
				rotation={[0.5, 0.5, 0]}
				polar={[-Math.PI, Math.PI]}
			>
				<group position={[0, 0, 0]}>
					<Preload all />
					<Center top>
						<Ring
							map={texture}
							rotation={[-Math.PI / 2.05, 0, 0]}
							scale={3}
						/>
					</Center>
					<AccumulativeShadows
						temporal
						frames={100}
						alphaTest={0.95}
						opacity={1}
						scale={20}
					>
						<RandomizedLight
							amount={8}
							radius={10}
							ambient={0.5}
							position={[0, 10, -2.5]}
							bias={0.001}
							size={3}
						/>
					</AccumulativeShadows>
				</group>
			</PresentationControls>
			<EffectComposer>
				<Bloom
					luminanceThreshold={1}
					intensity={0.85}
					levels={9}
					mipmapBlur
				/>
			</EffectComposer>
		</Canvas>
	);
}
