import { Switch,Route } from 'wouter'
import AniqueDetail1 from './components/AntiqueDetail1'
import AniqueDetail from './components/AntiqueDetail'
import Anique3D from './components/Antique3D'
export default function App(){
  return (
    <Switch>
      <Route path="/antique3D" component={Anique3D} />
      <Route path="/antiqueDetail1" component={AniqueDetail1}/>
      <Route path="/antiqueDetail" component={AniqueDetail}/>
  </Switch>
  )
}
