import React, {useRef, useMemo, useEffect, Suspense, forwardRef, useImperativeHandle, createRef, useState} from 'react';
import {Canvas, useLoader, extend, useFrame, useThree} from '@react-three/fiber';
import { FBXLoader } from 'three-stdlib';
import { useSpring, a } from '@react-spring/three';
import {Environment, Html, PresentationControls, Text} from '@react-three/drei';
import * as THREE from 'three';
import {
  BoxGeometry,
  MeshStandardMaterial,
  MeshPhysicalMaterial,
  DoubleSide,
  Box3,
  Vector3,
  ShaderMaterial
} from 'three';
import gsap from 'gsap';

// 自定义 ShaderMaterial
class FlowShaderMaterial extends ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        time: { value: 0 },
        speed: { value: 1.0 },
        u_glowColor: { type: 'c', value: new THREE.Color(0xffd700) } // 金色
      },
      vertexShader: /* glsl */`
        void main() {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: /* glsl */`
        uniform float time;
        uniform float speed;
        void main() {
          float glow = sin(time * speed) * 0.5 + 0.5;
          gl_FragColor = vec4(glow, glow, glow, 1.0);
        }
      `,
      transparent: true,
    });
  }
}

// 使用extend将FlowShaderMaterial添加到react-three-fiber的可用组件中
extend({ FlowShaderMaterial,MeshPhysicalMaterial });

const GlowingModel = ({ modelPath,scale,position }) => {
  const fbx = useLoader(FBXLoader, modelPath);
  const fbxRef = useRef();
  fbx.scale.set(...scale);
  fbx.position.set(...position);
  const shaderMaterialRef = useRef();
  const shaderMaterial = useMemo(() => new FlowShaderMaterial(), []);
  // shaderMaterialRef.current = shaderMaterial;
  // 定义动画的起始点和结束点的 Y 坐标
  const fromY = 1; // 顶部的 Y 坐标
  const toY = -1; // 底部的 Y 坐标

  // // 使用 useSpring 添加动画效果
  const { yPos } = useSpring({
    from: { yPos: fromY },
    to: { yPos: toY },
    config: { duration: 4000 },
    loop: true,
    reset: true,
  });
  useFrame(({ clock }) => {
    // if (fbxRef.current) {
    //   fbxRef.current.position.y = yPos.get();
    // }
    // // shaderMaterialRef.current.uniforms.time.value = clock.getElapsedTime();
  });
  //
  // // 遍历模型的每个子对象并应用 shaderMaterial
  // fbx.traverse((child) => {
  //   if (child.isMesh) {
  //     child.material = shaderMaterialRef.current;
  //   }
  // });

  // 返回带有自定义 shaderMaterial 的模型
  return <a.primitive
    object={fbx}
    ref={fbxRef}
    dispose={null}
  />;
};

const OutlineBox = ({ model }) => {
  const groupRef = useRef();
  const { scene } = useThree();

  useEffect(() => {
    const bbox = new THREE.Box3().setFromObject(model);
    const size = bbox.getSize(new THREE.Vector3());
    const edges = new THREE.EdgesGeometry(new THREE.BoxGeometry(size.x, size.y, size.z));
    const line = new THREE.LineSegments(
      edges,
      new THREE.LineBasicMaterial({ color: 0xffd700 })
    );
    groupRef.current.add(line);
    // 将线框对准模型的位置
    const center = bbox.getCenter(new THREE.Vector3());
    line.position.copy(center);
    // 添加到场景中
    scene.add(groupRef.current);
    // 清理资源
    return () => {
      groupRef.current.remove(line);
      edges.dispose();
      line.material.dispose();
    };
  }, [model, scene]);

  return <group ref={groupRef} />;
};

const LineWithLabel = ({ start, end, label }) => {
  // 计算线条中点
  const midPoint = new THREE.Vector3().lerpVectors(start, end, 0.5);

  const points = [];
  points.push(new THREE.Vector3(...start));
  points.push(new THREE.Vector3(...end));
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  return (
    <>
      <line geometry={geometry}>
        <lineBasicMaterial attach="material"  color="#FFD700" />
      </line>
      <Text
        position={[midPoint.x, midPoint.y, midPoint.z]}
        fontSize={0.2}
        color="#FFD700"
        anchorX="center"
        anchorY="middle"
      >
        {label}
      </Text>
    </>
  );
};

const CustomModel = ({ fbxPath,position, scale }) => {
  const fbx = useLoader(FBXLoader, fbxPath);
  const meshRef = useRef();
  const shieldSize = useRef(new Vector3());
  fbx.scale.set(...scale);
  fbx.position.set(...position);
  // 计算模型的边界框（BoundingBox）
  useEffect(() => {
    // 计算模型的边界框
    const bbox = new Box3().setFromObject(fbx);
    // 获取模型尺寸
    bbox.getSize(shieldSize.current);
    // 增加一定的间隙
    shieldSize.current.multiplyScalar(1.02);
  }, [fbx]);
  useFrame(() => {
    // 更新保护罩尺寸
    if (meshRef.current) {
      meshRef.current.scale.set(shieldSize.current.x, shieldSize.current.y, shieldSize.current.z);
    }
  });
  // 创建材质实例
  const material = new MeshPhysicalMaterial({
    color: "#FFFFFF",        // 玻璃通常没有颜色，但是可以根据需要调整
    transmission: 0.9,       // 透明度，0 完全不透明，1 完全透明
    roughness: 0,            // 表面粗糙度，0 表示光滑表面
    metalness: 0,            // 玻璃不是金属，所以金属度是 0
    reflectivity: 0.1,       // 一些轻微的反射
    side: DoubleSide,        // 双面材质
    clearcoat: 1.0,          // 清漆层增加额外的光泽和反射
    clearcoatRoughness: 0,   // 清漆层的粗糙度，0 表示光滑
    ior: 1.5,                // 玻璃的折射指数（Index of Refraction）
    envMapIntensity: 1,    // 环境映射强度，这会影响到反射效果
    // 如果你有环境映射纹理，你可以添加 envMap 属性来提供它
    // envMap: textureCubeMap
  });
  return (
    <>
      <primitive object={fbx} />
      <></>
    </>
  );
};



const ModelGroup = forwardRef(({modelPosition,modelScale,detailPosition},ref)=>{
  const camera = useThree((state) => state.camera);
  const controlsRef = useRef();
  const meshRef = useRef();
  const [isAutoRotating, setIsAutoRotating] = useState(true);

  // 在每帧更新时调用的函数，用于实现旋转动画
  useFrame(({ clock }) => {
    // 只有在允许自动旋转时才更新旋转
    if (isAutoRotating) {
      const elapsedTime = clock.getElapsedTime();
      meshRef.current.rotation.y = elapsedTime * 0.5; // 控制旋转的速度
    }
  });
  const zoomIn = (position, lookAt) => {
    gsap.to(camera.position, {
      x: position[0],
      y: position[1],
      z: position[2],
      duration: 2,
      onUpdate: () => camera.updateProjectionMatrix()
    });
    gsap.to(controlsRef.current?.target, {
      x: lookAt[0],
      y: lookAt[1],
      z: lookAt[2],
      duration: 2
    });
  };
  useImperativeHandle(ref,()=>(
    {
      changeTestFunc
    }
  ))
  const _detailPosition = new THREE.Vector3(detailPosition[0],detailPosition[1],detailPosition[2]);
  const position = [_detailPosition.x, _detailPosition.y, _detailPosition.z];
  const lookAt = _detailPosition;
  const changeTestFunc = (index) => {
    setIsAutoRotating(false);
    meshRef.current.rotation.y = 0;
    zoomIn(position,lookAt)
  }
  // 缩放并定位模型
  return(
    <PresentationControls
      ref={controlsRef}
      global
      speed={4}
      config={{ mass: 1, tension: 250, friction: 25 }}
      zoom={1}
      rotation={[0, 0, 0]}
      polar={[0, Math.PI/4]}
    >
      <mesh
        ref={meshRef}>
        <CustomModel
          fbxPath='https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/%E6%96%87%E7%89%A9/RMP.FBX'
          position={modelPosition}
          scale={modelScale}
        />
        <GlowingModel
          modelPath="https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/%E6%96%87%E7%89%A9/RMPXT.FBX"
          position={modelPosition}
          scale={modelScale}
        />
      </mesh>
    </PresentationControls>
  )
})

const Scene = forwardRef((props,ref) => {
  const modelPosition = [0, -2, 0]; // 根据实际情况调整模型位置
  const modelScale = [7, 7, 7]; // 根据实际情况调整模型缩放
  const c = createRef();
  const { curStatus,detailPosition } = props;
  useImperativeHandle(ref,()=>(
    {
      changeTestFunc
    }
  ))
  const changeTestFunc = () => {
    c.current.changeTestFunc()
  }
  return (
    <Canvas
      style={{display:'flex',justifyContent:'center',width:'100vw',height:`${curStatus === 'expand'?'100vh':'40vh'}`,zIndex:777 }}
    >
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Suspense fallback={null}>
        <ModelGroup
          ref={c}
          detailPosition={detailPosition}
          modelScale={modelScale}
          modelPosition={modelPosition}
        ></ModelGroup>
      </Suspense>
    </Canvas>
  );
});

export default Scene;
