import { useLayoutEffect, useRef, useState } from "react";
import { Slider } from "antd-mobile";
import { HeartOutline, PlayOutline } from "antd-mobile-icons";
import styled from "styled-components";
import {Image } from "antd-mobile";
function formatTime(value) {
	var time = "";
	var s = value.split(":");
	var i = 0;
	for (; i < s.length - 1; i++) {
		time += s[i].length === 1 ? "0" + s[i] : s[i];
		time += ":";
	}
	time += s[i].length === 1 ? "0" + s[i] : s[i];
	return time;
}
function transTime(value) {
	var time = "";
	var h = parseInt(`${value / 3600}`);
	value %= 3600;
	var m = parseInt(`${value / 60}`);
	var s = parseInt(`${value % 60}`);
	if (h > 0) {
		time = formatTime(h + ":" + m + ":" + s);
	} else {
		time = formatTime(m + ":" + s);
	}
	return time;
}
export default ({
	src = "https://img.tukuppt.com/newpreview_music/09/00/32/5c89189c4f4cf81405.mp3",
	cnSrc = "https://img.tukuppt.com/newpreview_music/09/00/32/5c89189c4f4cf81405.mp3",
	enSrc = "https://img.tukuppt.com/newpreview_music/09/00/32/5c89189c4f4cf81405.mp3"
}) => {
	const Audio = useRef();
	const [toggle, setToggle] = useState(true);
	const [progress, setProgress] = useState(0);
	const [duration, setDuration] = useState("00 : 00");
	const [currentTime, setCurrentTime] = useState("00:00");
	const [cnOrEn, setCnOrEn] = useState(false)

	useLayoutEffect(() => {
		if (Audio.current && src) {
			Audio.current.addEventListener("loadedmetadata", e => {
				const duration = transTime(e.target.duration);
				setDuration(duration);
			});
			Audio.current.addEventListener("play", _res => {
				setToggle(false);
			});
			Audio.current.addEventListener("pause", () => {
				setToggle(true);
			});
			Audio.current.addEventListener("timeupdate", e => {
				let value = e.target.currentTime / Audio.current.duration;
				setProgress(value * 100);
				setCurrentTime(transTime(e.target.currentTime));
			});
		}
		return () => {};
	}, []);
	const toastValue = value => {
		let rate = value / 100;
		Audio.current.currentTime = Audio.current.duration * rate;
	};
	const handlePaly = () => {
		if (toggle && src) {
			Audio.current?.play();
			return;
		}
		Audio.current?.pause();
		return;
	};
	const switchTheLanguage = () => {
		if (cnOrEn) {
			src = cnSrc
		} else {
			src = enSrc
		}
		setCnOrEn(!cnOrEn)
	}
	return (
		<StyleWrapper>
		{
			cnOrEn ? (<audio ref={Audio} src={enSrc} preload="metadata" controls={false}>
				当前不支持 audio 标签
			</audio>) :(<audio ref={Audio} src={cnSrc} preload="metadata" controls={false}>
				当前不支持 audio 标签
			</audio>)
		}
			{/* <audio ref={Audio} src={src} preload="metadata" controls={false}>
				当前不支持 audio 标签
			</audio> */}
			{/* <div className="audio-toggle">
				{
					cnOrEn ? (<img className="audio-explain" src={require('./img/explain-en.png')} onClick={switchTheLanguage}></img>):(<img className="audio-explain" src={require('./img/explain-cn.png')} onClick={switchTheLanguage} ></img>)
				}
				<div className="audio-timer">{currentTime}</div>
				<img className="audio-img" src={require('./img/audio.png')}></img>
				{
					toggle && src ? (<img className="audio-play" src={require('./img/play.png')} onClick={handlePaly}></img>):
					(<img className="audio-play" src={require('./img/pause.png')} onClick={handlePaly}></img>)
				}
			</div> */}
			{
			<div className="ip-post">
                <Image className="ren-ip" onClick={handlePaly}  src="https://dadiwan.oss-cn-hangzhou.aliyuncs.com/assets/icon/scene-03-ip.png"/>
                <Image className="ren-s"  src="https://dadiwan.oss-cn-hangzhou.aliyuncs.com/assets/images/viewantique-01-img_qipaozi.png"/>
            </div>
			}
		</StyleWrapper>
	);
};
const StyleWrapper = styled.div`
	height: 100%;
	width: 100%;
    .audio-progress{
        padding:0;
        .adm-slider-track{
            height:5px;
            .adm-slider-fill{
                height:5px
            }
        }
        .adm-slider-thumb-container,.adm-slider-thumb-container>.adm-slider-thumb{
            height:5px;
            width:5px;
        }
    }
    .audio-toggle {
		width: 100%;
		height: 100%;
        display: flex;
        ${'' /* justify-content: space-between; */}
        align-items: center;
        font-size:12px;
        color:#DF891F;
		.audio-explain {
			margin-left: 34.5px;
			width: 21px;
			height: 21px;
		}
		.audio-timer {
			margin-left: 7px;
		}
		.audio-img {
			width: 37.5px;
			height: 25.5px;
			margin-left: 44.5px;
		}
		.audio-play {
			position: absolute;
			right: 60px;
			width: 33.5px;
			height: 33.5px;
		}
	}
`;
