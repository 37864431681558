import React, {createRef, useEffect, useState} from "react";
import FlowingLine from "./FlowingLine";
import styled from "styled-components";
import {range} from "three/examples/jsm/nodes/shadernode/ShaderNodeElements";


const StyleWrapper = styled.div`
	@font-face {
		font-family: "SourceHanSerifCN";
		src: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/font/SourceHanSerifCN-Regular.ttf");
	}

	& * {
		transition: all 1s ease-out;
	}

	height: 100vh;
	display: flex;
	flex-direction: column;
	//background-color: #0a0a0a;
	background-image: url("https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E8%83%8C%E6%99%AF.png");

	.button-tool {
		position: absolute;
		z-index: 333;
	}

	.preview_retract {
		height: 40%;
		flex-shrink: 1;

		.preview_light {
			opacity: 0;
		}

		.preview_img {
			height: 70%;
		}

		.preview_text {
			display: flex;

			.preview-left {
			}

			.preview-left {
			}
		}
	}

	.preview_expand {
		height: 100%;
		flex-shrink: 0;

		.preview_light {
			opacity: 1;
		}

		.preview_img {
			height: 33%;
		}

		.preview_text {
			display: flex;

			.preview_text_name,
			.preview_text_icon,
			.preview_text_tips {
				opacity: 1;
				transition: all 2.5s ease-out;
			}

			.preview_text_look {
				opacity: 0;
				transition: all 0.3s ease-out;
			}
		}

		.preview_stage {
			opacity: 1;
		}
	}

	.preview {
		background-size: 100% 100%;
		position: relative;

		.preview_stage {
			position: absolute;
			bottom: 12%;
			width: 100%;
			height: 80px;
			left: 0;
			right: 0;
			transform: translate(1%, -25%);
			background: url("https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E7%BB%84%20453.png") no-repeat;
			background-size: 100% 100%;
		}

		.preview_img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			img {
				height: 100%;
			}

			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 80%;
				width: 100%;
				height: 100%;
				z-index: -1;
				background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_base.png") no-repeat;
				background-size: 100%;
			}
		}

		.preview_text {
			touch-action: none;
			position: absolute;
			z-index: 99999;
			bottom: 20px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: "SourceHanSerifCN";
			color: #ffffff;

			.preview_text__icon {
				width: 20px;
				height: 40px;
				position: absolute;
				bottom: 20px;
			}

			.preview_text__dot {
				display: flex;
				justify-content: center;
				padding-top: 10px;
				position: absolute;
				bottom: 30px;

				.dot {
					padding: 5px;
					margin-right: 10px;
					cursor: pointer;
					border-radius: 50%;
					background: #F2DCC2;
				}

				.dot.active {
					background: #ffffff;
				}
			}

			.left {
				left: 10px;
			}

			.right {
				right: 10px;
			}
		}
	}

	.detail-wrapper {
		flex: 1;
		z-index: 999;
		overflow: hidden;
		background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_bg.png") no-repeat;
		background-size: 100% 100%;
		//背景与第一个盒子相交处有白边
		padding-top: 3px;
		margin-top: -2px;
	}

	.detail {
		position: relative;
		height: 100%;
		padding-top: 20px;
		display: flex;
		flex-direction: column;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_contentBg.png") no-repeat;
		background-size: 100% 100%;
		background-color: #fff;
		font-family: "SourceHanSerifCN";

		& > * {
		}

		.detail_title {
			font-size: 22px;
			font-weight: bold;
			padding: 0 20px;
		}

		.detail_label {
			padding: 15px 20px;

			.adm-tag {
				padding: 4px 8px;
			}
		}

		.detail_voice {
			display: flex;
			align-items: center;
			height: 20px;
			padding: 15px;
			margin: 20px 20px 20px 40px;
			border-radius: 12px;
			background: #fff;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

			.detail_voice_taotao {
				transform: translate(-70%, -10px);
				height: 78px;
				width: 45px;
				background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_taotao.png") no-repeat;
				background-size: 100% 100%;
			}

			.detail_voice_audio {
				flex: 1;
				align-self: center;
			}
		}

		.detail_body {
			padding: 0 20px;
			margin-bottom: 10px;
			font-size: 14px;
			color: #4c4c4c;
			flex: 1;
			overflow: scroll;

			.detail_body_title {
				font-size: 22px;
				color: #000000;
			}

			.detail_body_content {
				text-indent: 2em;
				word-break: break-all;
				white-space: pre-line;
			}
		}

		.detail_comment {
			margin: 0 20px;
			height: 70px;
			display: flex;
			align-items: baseline;
			position: relative;

			.detail_comment_input {
				width: 50%;
				height: 50%;
				background: #f0ddbb;
				border-radius: 40px;
				--placeholder-color: #7f7f7f;
				--font-size: 14px;

				input {
					text-indent: 1em;
				}
			}

			.detail_comment_actions {
				height: 50%;
				position: absolute;
				right: 0;
				display: flex;
				align-items: center;
				font-size: 14px;

				.adm-button {
					padding: 0;
					margin-left: 8px;
					background: transparent;
					font-size: 14px;
					color: #7F7F7F;
					border: none;

					&::after {
						display: none
					}

					.adm-space {
						--gap: 5px
					}

					.adm-space, .adm-space > .adm-space-item {
						display: flex;
						align-items: center;
					}
				}

				.detail_comment_actions_forward, .detail_comment_actions_xing {
					display: block;
					width: 17px;
					height: 17px;

				}

				.detail_comment_actions_forward {
					background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_forward.png") no-repeat;
					background-size: 100% 100%;
				}

				.detail_comment_actions_xing {
					background: url("https://lordeal-oss-wuhan.oss-cn-beijing.aliyuncs.com/AntiqueDetail/AntiqueDetail_xing.png") no-repeat;
					background-size: 100% 100%;
				}
			}
		}
	}

	.detail-logo {
		margin: 10px;
		width: 300px;
		position: absolute;
	}

	.detail-title {
		font-family: SourceHanSerifCN;
		font-size: 16px;
		color: #FFF2D5;
		position: absolute;
	}

	.preview-question__wrapper {
		display: flex;
		flex-direction: column;
		position: absolute;
		z-index: 888;
		bottom: 0;
		width: 100%;
		padding: 20px;
		box-sizing: border-box;
		padding-bottom: 100px;
		background-image:linear-gradient(0deg, rgb(0 0 0), rgb(253 253 253 / 1%));

		&__title {
			font-family: SourceHanSerifCN;
			color: #F9DA99;
			font-size: 24px;
			padding-bottom: 10px;
			border-bottom: 1px dashed #F9DA99;
			margin-bottom: 10px;
		}

		&__info {
			display: flex;
			justify-content: space-evenly;
		}

		&__item {
			margin-right: 20px;
			width: 80px;
			height: 80px;
			border-radius: 10px;
			background-image: linear-gradient(90deg, rgba(249, 218, 153, 0.44), rgba(255, 255, 255, 0.34));
			border: 1px solid #F9DA99;

			.item-key {
				font-family: SourceHanSerifCN;
				color: #F9DA99;
				display: flex;
				flex-direction: column;
				padding: 4px;
			}

			.key-1 {
				font-size: 20px;
			}

			.key-2 {
				font-size: 30px;
			}
		}
	}

	.t-1 {
		left: 20px;
		top: 160px;
	}
`;

import config from "../config";
export default () => {
	const c = createRef();
	const [activeIndex, setActiveIndex] = useState(0);
	const [data,setData] = useState(config[`Q${activeIndex}`])
	const goToSlide = (index) => {
		if (index>4||index<0)return;
		c.current.changeTestFunc(index)
		setActiveIndex(index);
	};

	useEffect(()=>{
		setData(config[`Q${activeIndex}`])
	},[activeIndex])
	return (
		<StyleWrapper>
			<div className='preview'>
				<img className='detail-logo' src='https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E7%BB%84%20455.png'/>
				<span className='detail-title t-1'>
					{data?.desc}
				</span>
				<FlowingLine detailPosition={data?.detailPosition} curStatus='expand' ref={c}/>
				{
					!data?.noShowQuestion &&
					<div className='preview-question__wrapper'>
						<span className='preview-question__wrapper__title'>{`Q${activeIndex}`}:{data?.title}</span>
						<div className='preview-question__wrapper__info'>
							{
								data?.question.map(key=>(
									<div className='preview-question__wrapper__item'>
								<span className='item-key key-1'>
										{key.key1}
									</span>
										<span className='item-key key-2'>
										{key.key2}
									</span>
									</div>
								))
							}
						</div>
					</div>
				}
				<div>
					111
				</div>
				<div className="preview_text">
					<img onClick={()=>{goToSlide(activeIndex-1)}} className='preview_text__icon left' src='https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E7%BB%84%20334.png'/>
					<div className="preview_text__dot">
						{[1,2,3,4].map((_, index) => (
							<span
								key={index}
								className={`dot ${index === activeIndex ? 'active' : ''}`}
								onClick={() => goToSlide(index)}
							></span>
						))}
					</div>
					<img onClick={()=>{goToSlide(activeIndex+1)}} className='preview_text__icon right' src='https://lordeal-oss-ddw.oss-cn-beijing.aliyuncs.com/%E7%BB%84%20319.png'/>
				</div>
			</div>
		</StyleWrapper>
	);
};
