/*
 * @Author: 史许荣
 * @Date: 2022-08-01 10:04:06
 * @LastEditors: 史许荣
 * @LastEditTime: 2022-08-02 17:26:42
 * @Description: file content
 * @FilePath: \react-three-fiber-new\src\index.js
 */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './newApp'


createRoot(document.getElementById('root')).render(
  <Suspense fallback={null}>
    <App />
  </Suspense>
)
